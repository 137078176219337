import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getUser, isUserAuthenticated, authHeader } from '../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getEnvironment } from '../../../../../../_helpers/environmentProperties';
import toast from 'react-hot-toast';


const Popup = (props: any) => {
  const [show, setShowlist] = useState(props.show == true ? 0 : '');
  const [selectedMethod_two, setSelectedMethod_two] = useState('');
  const [CsvUrl, setCsvUrl] = useState<any>([]);
  const [select_pdf, setSelect_pdf] = useState('')


 const baseURL =
    getEnvironment() === 'production'
      ? process.env.REACT_APP_API_URL
      : process.env.REACT_APP_STAGING_API_URL;
  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
    // setBankResponseshow(false);
  };



  return (
    <>
      {/* <Modal
        show={show == 0 ? true : false}
        onHide={() => {
          setShowlist('');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
        <Box className="CloseContenar">
          <IconButton onClick={() => {
            onClose_topup();
          }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="heding_contean">
          <Typography>
            How do you want to export statement?
          </Typography>
        </Box>

        <Modal.Body>
          <div className="label">Download Options</div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'Niobi' ? 'active' : ''
                }`}
              onClick={() => {
                setSelectedMethod_two('Niobi')
                setShowlist(1);
                getCsvurl('Niobi')
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "70px", height: "45px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} height={'100%'} src='/Niobi-Logo (3).png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Standard</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'quickbooks' ? 'active' : ''
                }`}
              onClick={() => {
                setSelectedMethod_two('quickbooks')
                setShowlist(1);
                getCsvurl('quickbooks')
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/images.jpeg' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Quickbooks</p>
              </div>
            </div>
          </div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'sage' ? 'active' : ''
                }`}
              onClick={() => {

                setSelectedMethod_two('sage')
                setShowlist(1);
                getCsvurl('sage')
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/Sage-logo_svg.svg.png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Sage</p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'zoho' ? 'active' : ''
                }`}
              onClick={() => {
                setSelectedMethod_two('zoho')
                setShowlist(1);
                getCsvurl('zoho')
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/356-3560958_zoho-books-logo-hd-png-download.png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Zoho Books</p>
              </div>
            </div>
          </div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'xero' ? 'active' : ''
                }`}
              onClick={() => {

                setSelectedMethod_two('xero')
                setShowlist(1);
                getCsvurl('xero')
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/download.png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Xero</p>
              </div>
            </div>
          </div>
        </Modal.Body>

       
      </Modal> */}
    </>
  )
}
export default Popup;