import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./Topup.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectAllBalanceLIstData } from "../../../../../../_reducers/balance.reducer";
import { balanceActions } from "../../../../../../_actions/balance.actions";
import { isUserAuthenticated } from "../../../../../../_helpers";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Form, InputGroup } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { dashboardActions } from "../../../../../../_actions";
import {
    selectAllData,
} from "../../../../../../_reducers/dashboard.reducer";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface Props {
    closeModel: any;
    onSaveBank: any;
    currancyDestination: any;
    typeButton: any;
    balanceamount: any;
}
export const Doller = (props: Props) => {
    const [render, setRender] = useState(true);
    const [bankDetails, setBankDetails] = useState<any>();
    const [bankDetailsRender, setBankDetailsRender] = useState(true);
    const [bankDetailsRender_two, setBankDetailsRender_two] = useState(true);
    const [savePaymentDetailsRender, setSavePaymentDetailsRender] = useState(true);
    const [bankResponse, setBankResponse] = useState<any>();
    const [checked, setChecked] = React.useState(true);
    const [currencies, setCurrencies] = useState([])
    const [currencies_other, setCurrencies_other] = useState([])
    const dispatch = useDispatch();
    const [bankType, setBankType] = useState<any>([
        {
            "id": 1,
            "bank_id": 1,
            "key": "Pesalink",
            "description": "Credits within 1 hour",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 2,
            "bank_id": 1,
            "key": "RTGS",
            "description": "Credits within 2-3 hours",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 3,
            "bank_id": 1,
            "key": "EFT",
            "description": "Credits within 2-3 business days",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 4,
            "bank_id": 1,
            "key": "Swift",
            "description": "Credits within 2-4 business days",
            "created_at": null,
            "updated_at": null
        }
    ]);
    const [entity, setEntity] = useState<any>({
        Conversion_Currency: props.typeButton == "Receive payments" && props.currancyDestination == "USD" ? "" : props.typeButton == "Receive payments" ? "USD" : props.currancyDestination,
        Amount_to_Convert: '',
        Negotiated_Rate: '',
        Deal_Ticket_Number: props.typeButton == "Send payments" && props.currancyDestination == "USD" ? "" : props.typeButton == "Send payments" ? "USD" : props.typeButton == "Receive payments" ? props.currancyDestination : ""
    })
    const [balance, setBalance] = useState<any>();
    const [submit_data, setSubmit_data] = useState(false)
    const [balanceRender, setBalanceRender] = useState(false);
    const [currencies_rate, setCurrencies_rate] = useState(false)
    const [startgetapi, setStartgetapi] = useState(true);
    const [countryList, setCountrtList] = useState<any>([]);
    const [storeamount, setStoreAmount] = useState(props?.balanceamount)
    const [apirete, setApiRete] = useState(true)
    const[Balanceloader,setBalanceloader]=useState(false)
    const [currencies_rate_desplay, setCurrencies_rate_desplay] = useState<any>()
    const data = useSelector((state) => selectAllBalanceLIstData(state));
    const databalance = useSelector((state) => selectAllData(state));
    const dataList = useSelector((state) => selectPaymentJourneyData(state));

    console.log(storeamount,props.typeButton == "Receive payments" && entity.Deal_Ticket_Number != "USD" , "currencieshhhh");

    const handleChange_TWO = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (event.target.checked == true) {
            setEntity({
                Conversion_Currency: entity.Conversion_Currency,
                Amount_to_Convert: entity.Amount_to_Convert,
                Negotiated_Rate: '',
                Deal_Ticket_Number: entity.Deal_Ticket_Number
            })
        }


    };
    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(balanceActions.getBankDetailsById({ id: 1 }) as any);
            dispatch(balanceActions.getTopUpDetails_doller() as any);
            // if (props.typeButton == "Send payments") {
                dispatch(paymentJourneyActions.getCountriesBalances() as any);
            // } else {
                dispatch(paymentJourneyActions.getCurrenciesV2() as any);
                setTimeout(() => {
                    setStartgetapi(true);
                }, 4000)
            // }

            dispatch(paymentJourneyActions.getCountriesBalances() as any);
            dispatch(dashboardActions.getBalance("/wallet/get-balance",setBalanceRender,setBalanceloader) as any);
            // setTimeout(()=>{
            //     setBalanceRender(true)
            // },5000)
        }
    }, [render, isUserAuthenticated]);
    console.log(dataList, "dataList");

    useEffect(() => {
        if (startgetapi && dataList?.GETCURRENCIES_V2_SUCCESS !== undefined && props.typeButton == "Receive payments") {
            setCurrencies(dataList?.GETCURRENCIES_V2_SUCCESS);
            setStartgetapi(false);
        } else if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined && props.typeButton == "Send payments") {
            setCurrencies(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
            setCurrencies_other(dataList?.GETCURRENCIES_V2_SUCCESS);
            setStartgetapi(false);
        }
    }, [startgetapi, dataList]);
    useEffect(() => {
        if (currencies_rate && databalance?.GET_CURRENCY_RATE_SUCCESS !== undefined) {
            setCurrencies_rate(false);
            setCurrencies_rate_desplay(databalance.GET_CURRENCY_RATE_SUCCESS);
        }
    }, [databalance, currencies_rate]);
    useEffect(() => {
        if (balanceRender && databalance?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(databalance.GET_DASHBOARD_BALANCE_SUCCESS);
        }
    }, [databalance, balanceRender]);
    useEffect(() => {
        if (bankDetailsRender && data?.GET_BANK_DETAILS_ID_SUCCESS !== undefined) {
            setBankDetailsRender(false);
            setBankDetails(data.GET_BANK_DETAILS_ID_SUCCESS.data);
            setBankType(data.GET_BANK_DETAILS_ID_SUCCESS.data.methods)
        }
    }, [data, bankDetailsRender]);

    // useEffect(() => {
    //     if (bankDetailsRender_two && data?.GETCURRENCIES_SUCCESS !== undefined) {
    //         setBankDetailsRender_two(false);
    //         setCurrencies(data.GETCURRENCIES_SUCCESS)
    //     }
    // }, [data, bankDetailsRender_two]);
    const closeModel = () => {
        props.closeModel();
    }
    const theme = createTheme({
        palette: {
            secondary: {
                main: '#025041'
            },
            primary: {
                main: '#025041'
            }
        },
        status: {
            danger: 'red',

        },
    });
    useEffect(() => {
        if(apirete==true)
        setApiRete(false)
        var data = countryList
        let amountcard = 0
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.currency == entity.Conversion_Currency) {
                amountcard = element.total_balance / 100
            }
        }
        setStoreAmount(amountcard)
    }, [apirete])
    useEffect(() => {
        if (startgetapi && dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined) {
            setCountrtList(dataList?.GETCOUNTRIES_BALANCES_SUCCESS);
            setStartgetapi(false);

        }
    }, [startgetapi, dataList]);
    const Submit_page = () => {
        var data = entity.Negotiated_Rate.replaceAll(',', '') * entity.Amount_to_Convert.replaceAll(',', '')
        var body = {
            "negotiated_rate": parseInt(entity.Negotiated_Rate.replaceAll(',', '')),
            "source_currency": entity.Conversion_Currency,
            "destination_currency": entity.Deal_Ticket_Number,
            current: false,
            "unified": true,
            source_amount: entity.Amount_to_Convert,
            destination_amount: JSON.stringify(data)
        }
        console.log(body,"sdfdszfffffffzdgdg");
        
        dispatch(balanceActions.savePaymentcurrencyDetails(body) as any);
        props.onSaveBank();
        setTimeout(() => {
            closeModel()
        }, 3000)
    }
    return (
        <>
            <ThemeProvider theme={theme}>
          
          <Box className="CloseContenar">
            <IconButton
              onClick={() => {
                closeModel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>Send Payments via Currency conversion</Typography>
                         </Box>
                      
                {/* </div> */}
                <div className='company-details-container__wrapper'>
                    <div id='aDSaDaDadDddd' className='user-detail-container__wrapper topupbank'>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ margin: "0px 0px 15px 0px", width: "48%" }} className="input-form-wrapper dot">
                                <label>Source currency</label>
                                <Form.Select
                                    disabled={props.typeButton == "Receive payments" && props.currancyDestination == "USD" ? false : true}
                                    onChange={(e: any) => {
                                        setEntity({
                                            Conversion_Currency: e.target.value,
                                            Amount_to_Convert: entity.Amount_to_Convert,
                                            Negotiated_Rate: entity.Negotiated_Rate,
                                            Deal_Ticket_Number: entity.Deal_Ticket_Number
                                        })
                                        dispatch(dashboardActions.getBalance_currency_rate(e.target.value) as any)
                                        var data = countryList
                                        let amountcard = 0
                                        for (let index = 0; index < data.length; index++) {
                                            const element = data[index];
                                            if (element.currency == e.target.value) {
                                                amountcard = element.total_balance / 100
                                            }
                                        }
                                        setStoreAmount(amountcard)
                                        console.log(amountcard, "amountcard");

                                        setTimeout(() => {
                                            setCurrencies_rate(true)
                                        }, 3000)
                                    }}
                                    value={entity.Conversion_Currency}
                                    name="Conversion_Currency"
                                >
                                    <option selected value="">Source currency</option>
                                    {currencies?.map((arrayElement: any, index: number) => {
                                        return (
                                            <>
                                                {props.typeButton == "Receive payments" && props.currancyDestination == "USD" && arrayElement.currency == "USD" ? '' :
                                                    <option disabled={entity.Deal_Ticket_Number == arrayElement.title ? true : false} value={arrayElement.currency} key={index + 1}>{arrayElement.currency}</option>
                                                }
                                            </>)
                                    })}
                                </Form.Select>
                                {!entity.Conversion_Currency && submit_data == true ? <p className="Conversion_calss_data">Please select the conversion Currency</p> : ""}

                            </div>
                            <div style={{ margin: "0px 0px 15px 0px", width: "48%" }} className="input-form-wrapper dot">
                                <label>Destination currency</label>
                                <Form.Select
                                    disabled={props.typeButton == "Send payments" && props.currancyDestination == "USD" ? false : true}
                                    onChange={(e: any) => {
                                        setEntity({
                                            Conversion_Currency: entity.Conversion_Currency,
                                            Amount_to_Convert: entity.Amount_to_Convert,
                                            Negotiated_Rate: entity.Negotiated_Rate,
                                            Deal_Ticket_Number: e.target.value
                                        })


                                    }}
                                    value={entity.Deal_Ticket_Number}
                                    name="Deal_Ticket_Number"
                                >
                                    <option selected value="">Destination currency</option>
                                    {!!currencies_other&&props.typeButton == "Send payments"?
                                    <>
                                    {currencies_other?.map((arrayElement: any, index: number) => {
                                        return (
                                            <>
                                                {props.typeButton == "Send payments" && props.currancyDestination == "USD" && arrayElement.currency == "USD" ? '' :
                                                    <option disabled={entity.Conversion_Currency == arrayElement.title ? true : false} value={arrayElement.currency} key={index + 1}>{arrayElement.currency}</option>}
                                            </>
                                        )
                                    })}
                                    </>
                                    :
                                    <>
                                    {currencies?.map((arrayElement: any, index: number) => {
                                        return (
                                            <>
                                                {props.typeButton == "Send payments" && props.currancyDestination == "USD" && arrayElement.currency == "USD" ? '' :
                                                    <option disabled={entity.Conversion_Currency == arrayElement.title ? true : false} value={arrayElement.currency} key={index + 1}>{arrayElement.currency}</option>}
                                            </>
                                        )
                                    })}
                                    </>
                                }
                                </Form.Select>
                                {!entity.Deal_Ticket_Number && submit_data == true ? <p className="Conversion_calss_data">Please select the conversion currency</p> : ""}
                            </div>
                        </div>
                        {!!entity.Conversion_Currency ?
                            <p className="CURRENCY_TEXT">
                                You have  {props.typeButton == "Receive payments" && entity.Deal_Ticket_Number != "USD" ? !!balance ? (balance[1]?.total_balance / 100).toLocaleString('en', { minimumFractionDigits: 2 }) : "0.00" : storeamount} {entity.Conversion_Currency} available in your balance</p> : ""}

                        <div style={{ margin: "0px 0px 6px 0px" }} className="input-form-wrapper dot">
                            <label>Amount in source currency</label>
                            <InputGroup className="doc-input-wrapper">
                            <InputGroup.Text
                                className="input-mask"
                                id="basic-addon3"
                              >
                                {entity.Conversion_Currency}
                              </InputGroup.Text>
                              <Form.Control
                                    type="number"
                                    name="Deal_Ticket_Number"
                                    placeholder="Amount in Source Currency"
                                    onChange={(e: any) => {
                                        console.log(e.target.value, 'safaffafff');

                                        setEntity({
                                            Conversion_Currency: entity.Conversion_Currency,
                                            Amount_to_Convert: e.target.value,
                                            Negotiated_Rate: entity.Negotiated_Rate,
                                            Deal_Ticket_Number: entity.Deal_Ticket_Number
                                        })
                                    }}
                                />   
                           
                            </InputGroup>

                            {!entity.Amount_to_Convert && submit_data == true ? <p className="Conversion_calss_data">Please enter the amount to convert</p> : ""}
                        </div>
                        {/* <div style={{ display: 'flex', padding: "6px 0px", alignItems: "center" }}>
                            <Checkbox
                                className="Checkbox_className"
                                checked={checked}
                                onChange={handleChange_TWO}
                                icon={<img src='/Rectangle 2367.svg'
                                    width={'16px'} />}
                                checkedIcon={<img src='/Group.svg' width={"21px"} />}
                                {...label} />
                            <p className="current_text_rete">Current exchange rate</p>
                        </div> */}
                        <Tooltip title="Before you request a top up via currency conversion, you can reach out at support@niobi.co and get the day's applicable exchange rate.">

                            <div style={{ margin: "0px 0px 15px 0px" }} className="input-form-wrapper dot">
                                <label>Exchange rate </label>
                                <>
                                    <InputGroup className="doc-input-wrapper">
                                        <Form.Control

                                            type="number"
                                            // disabled={checked == true ? true : false}
                                            name="Negotiated_Rate"
                                            placeholder="Exchange Rate"
                                            onChange={(e: any) => {
                                                setEntity({
                                                    Conversion_Currency: entity.Conversion_Currency,
                                                    Amount_to_Convert: entity.Amount_to_Convert,
                                                    Negotiated_Rate: e.target.value,
                                                    Deal_Ticket_Number: entity.Deal_Ticket_Number
                                                })
                                            }}
                                        />
                                    </InputGroup>
                                </>
                            </div>
                        </Tooltip>
                        <div style={{ margin: "0px 0px 15px 0px" }} className="input-form-wrapper">
                            <label>Amount topped up</label>
                            <InputGroup className="doc-input-wrapper">
                            <InputGroup.Text
                                className="input-mask"
                                id="basic-addon3"
                              >
                                {entity.Deal_Ticket_Number}
                              </InputGroup.Text>
                                <Form.Control
                                    name="Negotiated_Rate cc"
                                    type="text"
                                    disabled
                                    placeholder="Amount Topped Up"
                                    value={
                                        // checked == true ?
                                        //     props.currancyDestination != "KES" ?
                                        //         ((!!currencies_rate_desplay ?
                                        //             (1 / currencies_rate_desplay.rate.rate) : 0) *
                                        //             entity.Amount_to_Convert.replaceAll(',', '')) :
                                        //         ((!!currencies_rate_desplay ?
                                        //             (currencies_rate_desplay.rate.rate) : 0) *
                                        //             entity.Amount_to_Convert.replaceAll(',', '')) :
                                        entity.Negotiated_Rate.replaceAll(',', '') *
                                        entity.Amount_to_Convert.replaceAll(',', '')
                                    }
                                />
                            </InputGroup>
                        </div>
                        <div
                            id='SAdfafafasdasD'
                            style={{ marginTop: '44px' }}
                            className="setting-modal__btn-row d-flex flex-center justify-content-center align-items-center gap-10">
                            <div className="edrgrezgeff">
                                {/* <Button id='adadadaddddd' style={{ marginRight: "0px" }} className='complete-profile__saventmm' variant='custom' onClick={closeModel}>   Close  </Button> */}
                            </div>
                            <div className="edrgrezgeff">
                                <Button id='adadadaddddd'
                                    disabled={!!entity.Amount_to_Convert &&
                                        !!entity.Conversion_Currency &&
                                        !!entity.Negotiated_Rate &&
                                        !!entity.Deal_Ticket_Number && checked == false ? false :
                                        !!entity.Amount_to_Convert &&
                                            !!entity.Conversion_Currency &&
                                            !!entity.Deal_Ticket_Number && checked == true ? false : true
                                    }
                                    className='complete-profile__submitbtnlist'
                                    onClick={() => {
                                        Submit_page()
                                        setSubmit_data(true)
                                    }}> Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    )
}
