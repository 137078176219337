import { Avatar, Button, Typography } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from 'react';
import { userActions } from "../../../_actions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { registerSelector } from "../../../_reducers/registration.reducer";
import { useLocation } from 'react-router-dom';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export const Changepassword = () => {

  const [submitted, setSubmitted] = useState(false);
  const [showPasswordlistdata, setShowPasswordlistdata] = React.useState(false)
  const [showPasswordlistdata2, setShowPasswordlistdata2] = React.useState(false)
  const [showPasswordlistdata3, setShowPasswordlistdata3] = React.useState(false)
  const [registerRender, setregisterRender] = useState(true);
  const [regfailerRender, setregfailerRender] = useState(true);
  const [registerReqeust, setregisterReqeust] = useState(true);
  const [apiSuccRender, setapiSuccRender] = useState(true);
  const [cpwShow, setCpwShow] = useState(false);
  const [findToken, setFindToken] = useState('');

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const dispatch = useDispatch();
  const verifyTokenData = useSelector((state) =>
    registerSelector(state)
  );

  const handleClickShowPassword = () => setShowPasswordlistdata((show: any) => !show);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword2 = () => setShowPasswordlistdata2((show: any) => !show);

  const handleClickShowPassword3 = () => setShowPasswordlistdata3((show: any) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const registerData = useSelector((state) =>
    registerSelector(state)
  );
  function resetResquest() {
    setregisterRender(true);
    setregfailerRender(true);
  }

  const location = useLocation()
  useEffect(() => {
    const useQuery = new URLSearchParams(location.search);
    let query = useQuery.get('token');
    if (!!query) {
      setFindToken(query)
      dispatch(userActions.verifyToken({ token: query }) as any);
    }
  }, []);

  useEffect(() => {
    if (apiSuccRender && verifyTokenData?.VERIFYTOKEN_SUCCESS !== undefined) {
      setapiSuccRender(false)
      setCpwShow(true)
    }
  }, [verifyTokenData, apiSuccRender]);

  const validationSchema = Yup.object().shape({
    password: Yup
      .string()
      .required('Please enter new password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Please enter a valid Password in the required field"
      ),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Please enter the same password in both fields"
    ).required('Please enter a value in the confirm password field'),

  });

  const handleSubmit = (e: any) => {
    var req = {
      token: findToken,
      password: e.password,
      confirm_password: e.confirm_password,
    };

    setSubmitted(true);
    if (e.password) {
      console.log('ready for dispatched', req);
      dispatch(userActions.changePassword({ req },'https://users.niobi.co/api/payments') as any);
    }
  }
  const [user, setUser] = useState({
    password: "",
    confirm_password: "",
  });
  return (
    <>
      {cpwShow ?
        <>
          {/* <ThemeProvider theme={theme}> */}
          <div className="register-container">
          <div className="formBx">

              <p className="updatepasstext">Change password</p>
              <div className='user-detail-container__wrapper'>
                <Formik
                  initialValues={user}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, isSubmitting, handleChange, values }) => (
                    <Form className="font-14">
                      <div className='input-form-wrapper'>
                        <label>New password</label>
                        <div className="position-relative">
                          <input
                            name="password"
                            type={showPasswordlistdata ? 'text' : 'password'}
                            onChange={handleChange}
                            value={values.password}
                            className={
                              "form-text form-control text-field" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback red"
                          />
<ThemeProvider theme={theme}>
                          <Button
                            // className={styles.menolistlogo22}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            className="viewbbtn"
                          >
                            {showPasswordlistdata ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Button>
                          </ThemeProvider>
                        </div>
                        <ThemeProvider theme={theme}>

                        <Typography className="helptext mt-1"  gutterBottom>Please enter a valid Password in the required field</Typography>
</ThemeProvider>
                      </div>
                      <div className='input-form-wrapper'>
                        <label>Confirm password</label>
                        <div className="position-relative">
                          <input
                            name="confirm_password"
                            type={showPasswordlistdata2 ? 'text' : 'password'}
                            onChange={handleChange}
                            value={values.confirm_password}
                            className={
                              "form-text form-control text-field" +
                              (errors.confirm_password && touched.confirm_password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="confirm_password"
                            component="div"
                            className="invalid-feedback red"
                          />
                          <ThemeProvider theme={theme}>

                          <Button
                            // className={styles.menolistlogo22}
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                            className="viewbbtn"
                          >
                            {showPasswordlistdata2 ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Button>
                          </ThemeProvider>
                        </div>
                      </div>
                      <ThemeProvider theme={theme}>

                      <Button className='mt-3 complete-profile__btn2 text-capitalize' type="submit" >
                        {(loading == true) && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Change password</Button>
                        </ThemeProvider>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            </div>
          {/* </ThemeProvider> */}
        </>
        : ""}
    </>
  )
}