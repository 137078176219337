import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Avatar from "@mui/material/Avatar";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Button } from "react-bootstrap";
import "../PayOptions/Payoptions.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { Box, CircularProgress } from "@mui/material";
const USDExpressPayment = (props: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [datadisplay, setDatadisplay] = useState('')
    const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false)
    const [data_ahahhahhajaja, setData_sjshhsgsjs] = useState(false)
    const [selectMethod, setSelectMethod] = useState("")
    const [selectType, setSelectType] = useState("")
    const [selectMethodType, setSelectMethodType] = useState('')
    const [startAPI, setStartAPI] = useState(false)
    const [chackBalance, setChackBalance] = useState(true)
    const [LoaderPage, setLoaderPage] = useState(false)
    const dataList = useSelector((state) => selectPaymentJourneyData(state))

    useEffect(() => {
        const popup = searchParams.get('type');
        if (popup == 'Express Payment KES') {
            setDatadisplay(popup)
        } else if (popup == 'Express Payment USD') {
            setDatadisplay(popup)
        } else {
        }
    }, [searchParams]);
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const onSelect = (el: string) => {
        props.onSelect(el);

    }
    const onSelect_modefe = (el: string) => {
        props.onSelect_modefe(el)
    }
    useEffect(() => {
        if (startAPI && dataList?.CHACKBALANCE_SUCCESS !== undefined) {
            if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
                setStartAPI(false)
                setLoaderPage(false)
                console.log(selectMethodType,"selectMethodType");

                if (selectMethodType == "RTGS") {
                    onSelect('RTGS')
                } else if (selectMethodType == "EFT") {
                    onSelect('EFT')
                } else if (selectMethodType == "SWIFT") {
                    onSelect('SWIFT')
                } else if (selectMethodType == "Digital currency") {
                    console.log(selectMethodType,"selectMethodType");

                    onSelect('Digital Currency')
                }
console.log(selectMethodType,"selectMethodType");

                setChackBalance(true);
            } else {
                setChackBalance(false);
                setStartAPI(false)
                setLoaderPage(false)
            }
        }
    }, [startAPI, dataList?.CHACKBALANCE_SUCCESS])
    return (
        <>
            {LoaderPage && (
                <ThemeProvider theme={theme}>
                    <Box
                        sx={{
                            display: "flex",
                            position: "absolute",
                            top: 150,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 10,
                        }}
                    >
                        <CircularProgress color="success" />
                    </Box>
                </ThemeProvider>
            )}
            <div className='m-pesa-container'>
                <div className="calss_accordion_contenar">
                    <ThemeProvider theme={theme}>
                        <div className="maian_adaat_listtts" onClick={() => {
                            if (paymentacodiyan == false) {
                                setPaymentacodiyan(true)
                            } else {
                                setPaymentacodiyan(false)
                            }
                        }}>
                            <div className="avayar_text">

                                <Avatar className="Avatar_fast">3</Avatar>
                                <p className="accordion_p_data_contean">Payment Method</p>
                            </div>

                            {paymentacodiyan == false ?
                                <ExpandMoreIcon style={{ color: "#1D1D1D" }} /> : <ExpandLessIcon style={{ color: "#1D1D1D" }} />}
                        </div>
                        {paymentacodiyan == true ?
                            <div className="accordion_details_contean">
                                <div className="mainana_cantena_daat_potrrr">
                                    {props.button_dess == true ?
                                        <Button className={selectMethod == "Bank transfer" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                        disabled={props.item.amount <= 99 == true ? true : false}
                                            onClick={() => {
                                                setSelectMethod("Bank transfer")
                                            }}>
                                            Bank Transfer
                                        </Button> : <Button
                                            className={selectMethod == "Bank transfer" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                            onClick={() => {
                                                onSelect_modefe('')
                                                setData_sjshhsgsjs(true)

                                            }}>
                                            Bank Transfer
                                        </Button>}
                                    {(props.button_dess == true)
                                        ?
                                        <Button
                                        disabled={props.item.amount <= 99 == true ? true : false}
                                            className={selectMethod == "Digital currency" ? "Minn_button_mpesa_datata" : "calss_not_active_data"} onClick={() => {
                                                setSelectMethodType("Digital currency")
                                                setSelectMethod("Digital currency")
                                                props.ChackBalancefun('digital',"digital","Bank Transfer")
                                                setLoaderPage(true)
                                                setTimeout(() => {
                                                    setStartAPI(true)
                                                }, 5000)
                                            }}>
                                            Digital currency
                                        </Button> : <Button
                                            className={selectMethod == "Digital currency" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                            onClick={() => {
                                                onSelect_modefe('')
                                            }}>
                                            Digital currency
                                        </Button>}
                                </div>
                                {/* {props.chackBalance==false?<div className='mpesa-container__errorMsg'> Please top up your account to cover the payment.</div>:""} */}
                                {selectMethod == "Bank transfer" && props.item.amount > 99 ?
                                    <div style={{ display: "block" }} className="mainana_cantena_daat_potrrr">
                                        <Button style={{ width: "100%", marginBottom: "10px" }}
                                            className={selectType == "RTGS" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                            onClick={() => {
                                                setSelectMethodType("RTGS")
                                                props.ChackBalancefun('rtgs','rtgs',"Bank Transfer")
                                                setLoaderPage(true)
                                                setTimeout(() => {
                                                    setStartAPI(true)
                                                }, 5000)

                                            }}>
                                            RTGS
                                             <br />
                                        <span className="description">Debits within 2-3 hours</span>
                                        <p className='description_limit'>No transaction limit</p>
                                        </Button>
                                        <Button style={{ width: "100%", marginBottom: "10px" }}
                                            className={selectType == "EFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                            onClick={() => {
                                                setSelectMethodType("EFT")
                                                props.ChackBalancefun('eft','eft',"Bank Transfer")
                                                setLoaderPage(true)
                                                setTimeout(() => {
                                                    setStartAPI(true)
                                                }, 5000)
                                            }}>
                                            EFT
                                            <br />
                                        <span className="description">Debits within 2-3 business days</span>
                                        <p className='description_limit'>No transaction limit</p>
                                        </Button>
                                        <Button style={{ width: "100%", marginBottom: "10px" }}
                                            className={selectType == "SWIFT" ? "Minn_button_mpesa_datata" : "calss_not_active_data"}
                                            onClick={() => {
                                                setSelectMethodType("SWIFT")
                                                props.ChackBalancefun('swift','SWIFT_USD',"Bank Transfer")
                                                setLoaderPage(true)
                                                setTimeout(() => {
                                                    setStartAPI(true)
                                                }, 5000)

                                            }}>
                                            SWIFT
                                              <br />
                                        <span className="description">Debits within 2-3 business days</span>
                                        <p className='description_limit'>No transaction limit</p>
                                        </Button>
                                    </div>

                                    : ""}
                            </div> : ""}
                            {chackBalance == false ? <div className='mpesa-container__errorMsg'>
                            Please top up your account to cover the payment.
                        </div> : ""}
                        {!!props.item && props.item.amount <= 99 ? <div style={{ padding: "10px 0px" }} className='mpesa-container__errorMsg'>
                        Please enter an amount greater than or equal to USD 100.</div> : ""}
                    </ThemeProvider>
                </div>
            </div>
        </>
    )
}
export default USDExpressPayment;