import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import "./MPesa.scss";
import { useNavigate } from "react-router-dom";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import { useDispatch, useSelector } from "react-redux";
import { payablesActions } from "../../../../../../_actions/payables.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { isUserAuthenticated } from "../../../../../../_helpers";
import { selectAllData } from "../../../../../../_reducers/dashboard.reducer";
import { useSearchParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { userActions, dashboardActions } from "../../../../../../_actions";
import PhoneInput from 'react-phone-input-2'
import { Box, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
interface Props {
    onSave: any;
    onCancel: any;
    counterpart: any;
    item: any;
    bodypyement: any
    button_dess: any
    transactionExist: any;
    ChackBalancefun: any;
    setAccountName: any
}
interface countryProps {
    dialCode: string
}

export const MPesa = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [option, setOption] = useState('');
    const [render, setRender] = useState(true);
    const [feesNew, setFeesNew] = useState<any>();
    const [balance, setBalance] = useState<any>();
    const [feesNewRender, setFeesNewRender] = useState(true);
    const [feesNewRender_two, setFeesNewRender_two] = useState(false);
    const [balanceRender, setBalanceRender] = useState(false);
    const [itemm, setitem] = useState<any>();
    const [itemSelectedRender, setitemSelectedRender] = useState(true);
    const [paybleOption, setPaybleOption] = useState(true);
    const [errorShow, setErrorShow] = useState(false);
    const [itemRender, setItemRender] = useState(true);
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const data = useSelector((state) => selectAllData(state));
    const dispatch = useDispatch();
    const [phoneinit, setphoneinit] = useState(true);
    const [country_code, setcountry_code] = useState("");
    const [phone, setPhone] = useState("");
    const [datadisplay, setDatadisplay] = useState('')
    const [button_desebal, setButton_desebal] = useState()
    const [button_dataget, setButton_dataget] = useState()
    const [new_responss_data, setNwe_respons_data] = useState()
    const [loading, setLoader] = useState(false);
    const [phoneVlide, setPhoneVlide] = useState("");
    const [Error, setError] = useState(false)
    const [startAPI, setStartAPI] = useState(false)
    const [ChackBalance, setChackBalance] = useState(true)
    const [LoaderPage, setLoaderPage] = useState(false)
    const [cNupdate, setcNupdate] = useState<any>({
        phone_number: null,
        paybill_number: null,
        account_number: null,
        till_number: null
    });
    const [tillRender, setTillRender] = useState(false);
    const [Balanceloader, setBalanceloader] = useState(false)

    const [rqBody, setrqBody] = useState<any>({
        amount: null,
        counterpart: null,
        type: null,
        tags: []
    });
    const [submittedData, setSubmittedData] = useState<any>({
        phone_number: null,
        paybill_number: null,
        account_number: null,
        till_number: null
    });
    useEffect(() => {
        const popup = searchParams.get('type');
        if (popup == 'Express Payment KES') {
            setDatadisplay(popup)
            setcNupdate(props.bodypyement.counterpart)
        } else {
        }
    }, [searchParams]);
    console.log(button_desebal, paybleOption, "button_desebal");
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const handleOnChange = (
        value: any,
        country: any,
        e: any,
        formattedValue: any
    ) => {
        setSubmittedData({ ...submittedData, 'phone_number': value })
    };
    useEffect(() => {
        setButton_desebal(props.button_dess)
    }, [props.button_dess])
    useEffect(() => {
        setButton_dataget(props.bodypyement)
        setrqBody(props.bodypyement);
    }, [props.bodypyement])
    useEffect(() => {
        if (
            itemRender &&
            props.counterpart !== undefined
        ) {
            setItemRender(false);
            if (!!props.counterpart?.phone_number) {
                setPhone(props.counterpart?.phone_number)
                setPhoneVlide(props.counterpart?.phone_number)
            }
            setSubmittedData({
                phone_number: props.counterpart?.phone_number,
                paybill_number: props.counterpart?.paybill_number,
                account_number: props.counterpart?.account_number,
                till_number: props.counterpart?.till_number
            });
        }
    }, [props.counterpart, itemRender]);
    useEffect(() => {
        if (datadisplay == "Express Payment KES") {
            setitem(props.bodypyement)
        } else {
            setitem(props.item);
        }

    }, [props]);

    useEffect(() => {
        if (isUserAuthenticated() && render && !!itemm?.amount) {
            setRender(false);
            dispatch(dashboardActions.getBalance("/wallet/get-balance", setBalanceRender, setBalanceloader) as any);
            // dispatch(paymentJourneyActions.getFeesNew(itemm?.amount) as any)
        }
    }, [render, isUserAuthenticated]);
    let navigate = useNavigate();

    useEffect(() => {
        if (feesNewRender && dataList?.GETNEWFEES_SUCCESS !== undefined) {
            setFeesNewRender(false);
            setFeesNew(dataList.GETNEWFEES_SUCCESS);
        }
    }, [dataList, feesNewRender]);


    useEffect(() => {
        if (!!itemm && itemm.amount != undefined) {
            // dispatch(paymentJourneyActions.getFeesNew(itemm?.amount) as any)
        }
    }, [itemm]);
    useEffect(() => {
        if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
        }
    }, [data, balanceRender]);

    const handleSubmit = (e: any) => {
        const { name, value }: any = e.target;

        setSubmittedData({ ...submittedData, [name]: value })
    }

    const onSave = (reqBoody: any) => {
        props.onSave(option, reqBoody);
    }
    const onCancel = () => {
        props.onCancel();
    }

    const handleOpenPopup = (e: any) => {
        let path = `/balance?type=topup`;
        navigate(path, { state: { type: 'topup', color: 'green' } });
    }

    const dataadd = () => {
        if (option === 'pay bill' && !!submittedData.paybill_number && !!submittedData.account_number) {

            dispatch(paymentJourneyActions.getPaybillNew(submittedData.paybill_number) as any)
            // dispatch(paymentJourneyActions.getValidateAccount(submittedData.paybill_number, "PayBill") as any)
            setLoader(true)
            setTimeout(() => { setFeesNewRender_two(true) }, 3500)
        }
    }
    const till_numberFun = () => {
        // dispatch(paymentJourneyActions.getValidateAccount(submittedData.till_number, "TillNumber") as any)
        setLoader(true)
        setTimeout(() => { setTillRender(true) }, 4000)
    }
    console.log(feesNewRender_two, 'nshshshsshs');
    useEffect(() => {
        if (tillRender && dataList?.GETVALIDATE_ACCOUNT_SUCCESS !== undefined) {
            if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == true) {
                onSubmitted()
                setTillRender(false)
                props.setAccountName(dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.data?.name)
                // setLoader(false)
            } else if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == false) {
                setLoader(false)
                setTillRender(false)
                // toast.error(dataList?.GETVALIDATE_ACCOUNT_SUCCESS.message, { duration: 8000 })
            }
        }
    }, [tillRender, dataList?.GETVALIDATE_ACCOUNT_SUCCESS]);
    useEffect(() => {
        if (feesNewRender_two && dataList?.GET_PAYBILL_NEW_SUCCESS !== undefined) {
            setFeesNewRender_two(false);
            console.log(feesNewRender_two, 'nshshshsshs');
            if (dataList.GET_PAYBILL_NEW_SUCCESS.success == true) {
                // if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS !== undefined) {
                //     console.log(dataList?.GETVALIDATE_ACCOUNT_SUCCESS, "dfsDFfdfffSFF");
                //     if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == true) {
                //         onSubmitted()
                //         // setLoader(false)
                //         props.setAccountName(dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.data?.name)
                //     } else if (dataList?.GETVALIDATE_ACCOUNT_SUCCESS?.success == false) {
                //         setLoader(false)
                //         toast.error(dataList?.GETVALIDATE_ACCOUNT_SUCCESS.message, { duration: 8000 })
                //     }

                // }
                onSubmitted()
                setLoader(false)
                console.log('nshshshsshs');
                if (!!dataList.GET_PAYBILL_NEW_SUCCESS.message) {
                    toast.success(dataList.GET_PAYBILL_NEW_SUCCESS.message, { duration: 8000 })
                }

            } else {
                console.log('nshshshsshs');
                toast.error(dataList?.GET_PAYBILL_NEW_SUCCESS.message, { duration: 8000 })
                setLoader(false)
            }
        }
    }, [feesNewRender_two]);
    console.log(feesNewRender_two, 'nshshshsshs');
    const onSubmitted = () => {
        console.log('nshshshsshs');
        if (option === 'send money') {
            props.ChackBalancefun('send money',"MPESA-B2C-DARAJA",'Mobile Money','','','','','')
            setLoaderPage(true)
            setTimeout(() => {
                setStartAPI(true)
            }, 5000)

        } else if (option === 'pay bill') {
            props.ChackBalancefun('pay bill',"MPESA-B2B-PAYBILL",'Mobile Money', submittedData.account_number,'paybill',submittedData.paybill_number,'','')
            setLoaderPage(true)
            setTimeout(() => {
                setStartAPI(true)
            }, 5000)
        } else if (option === 'till number') {
            props.ChackBalancefun('till number',"MPESA-B2B-TILL",'Mobile Money','','','','','')
            setLoaderPage(true)
            setTimeout(() => {
                setStartAPI(true)
            }, 5000)
        }
    }
    useEffect(() => {
        if (startAPI && dataList?.CHACKBALANCE_SUCCESS !== undefined) {
            if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
                setStartAPI(false)
                setLoaderPage(false)
                if (datadisplay == 'Express Payment KES') {
                    var body = props.bodypyement
                    body.type = option
                    var newBody = cNupdate;
                    var reqBoody = rqBody;
                    let request: any = '';
                    if (option === 'send money') {
                        newBody = { ...newBody, ['phone_number']: submittedData.phone_number }
                        setcNupdate({ ...newBody, ['phone_number']: submittedData.phone_number })
                    } else if (option === 'pay bill') {
                        newBody = { ...newBody, ['paybill_number']: submittedData.paybill_number }
                        newBody = { ...newBody, ['account_number']: submittedData.account_number }
                        setcNupdate({ ...newBody, ['paybill_number']: submittedData.paybill_number })
                        setcNupdate({ ...newBody, ['account_number']: submittedData.account_number })
                    } else {
                        newBody = { ...newBody, ['till_number']: submittedData.till_number }
                        setcNupdate({ ...newBody, ['till_number']: submittedData.till_number })
                    }
                    if (option === 'send money') {
                        request =
                        {
                            id: props.counterpart?.monite_counterpart_id,
                            phone_number: submittedData.phone_number
                        };
                    } else if (option === 'pay bill') {
                        request = {
                            id: props.counterpart?.monite_counterpart_id,
                            paybill_number: submittedData.paybill_number,
                            account_number: submittedData.account_number
                        };
                    } else if (option === 'till number') {
                        request = {
                            id: props.counterpart?.monite_counterpart_id,
                            till_number: submittedData.till_number
                        };
                    }
                    reqBoody = { ...reqBoody, ['counterpart']: newBody }
                    setNwe_respons_data(reqBoody)
                    var valuse = submittedData?.account_number
                    const sanitizedValue = valuse?.replace(/[^\w\s]/gi, '');

                    if (option === 'pay bill' && valuse !== sanitizedValue) {

                        dispatch(userActions.getOtp(true) as any)
                    } else {
                        dispatch(userActions.getOtp(false) as any)
                    }
                    props.transactionExist(option)

                    dispatch(payablesActions.getOtpverifay() as any);
                    if (!!props.counterpart?.monite_counterpart_id) {
                        dispatch(payablesActions.createPayableRequest(request) as any)
                    } {

                    }
                    setLoader(false)
                    onSave(reqBoody);
                } else {
                    let request: any = '';
                    if (option === 'send money') {
                        request =
                        {
                            id: props.counterpart?.monite_counterpart_id,
                            phone_number: submittedData.phone_number
                        };
                    } else if (option === 'pay bill') {
                        request = {
                            id: props.counterpart?.monite_counterpart_id,
                            paybill_number: submittedData.paybill_number,
                            account_number: submittedData.account_number
                        };
                    } else if (option === 'till number') {
                        request = {
                            id: props.counterpart?.monite_counterpart_id,
                            till_number: submittedData.till_number
                        };
                    }

                    var valuse = submittedData?.account_number
                    const sanitizedValue = valuse?.replace(/[^\w\s]/gi, '');
                    if (option === 'pay bill' && valuse !== sanitizedValue) {

                        dispatch(userActions.getOtp(true) as any)
                    } else {
                        dispatch(userActions.getOtp(false) as any)
                    }
                    props.transactionExist(option)
                    dispatch(payablesActions.getOtpverifay() as any);
                    dispatch(payablesActions.createPayableRequest(request) as any)
                    onSave(request);
                }
                setChackBalance(true);
            } else {
                setChackBalance(false);
                setLoaderPage(false)
                setStartAPI(false)
                setLoader(false)
            }
        }
    }, [startAPI, dataList?.CHACKBALANCE_SUCCESS])
    return (
        <>
            {LoaderPage && (
                <ThemeProvider theme={theme}>
                    <Box
                        sx={{
                            display: "flex",
                            position: "absolute",
                            top: 150,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 10,
                        }}
                    >
                        <CircularProgress color="success" />
                    </Box>
                </ThemeProvider>
            )}
            <div className='mpesa-container'>
                <div className='mpesa-container__heading'>
                    <img src='/assets/mpesa_logo.svg' />
                </div>
                <div className={`mpesa-container__option-wrapper mpaisa ${option === 'send money' ? 'border-green' : ""} ${!!itemm && itemm.amount < 10 ? 'disabled' : ''}`}
                    onClick={() => {
                        if (!!itemm && itemm.amount >= 10) {
                            setErrorShow(false)
                            setPaybleOption(true)
                            // if (!!balance[0]?.total_balance && feesNew && itemm && itemm.amount) {
                            //     if ((balance[0]?.total_balance / 100) <= (feesNew['MPESA B2C DARAJA'] + itemm.amount)) {
                            // setPaybleOption(false)
                            // setErrorShow(true)
                            //     }
                            // }

                            setOption('send money')
                        }
                    }}
                >
                    <div className='mpesa-container__option-wrapper__txt' style={{ display: "flex", alignItems: "center" }}>
                        <div className="imgMetheds">
                            <img src='https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/mpesa-kenya.jpeg' width="100%" height="100%" alt="" />
                        </div>
                        Send Money</div>
                    {option === 'send money' ? (<UpArrow />) : (<DownArrow />)}
                </div>
                {option === 'send money' && (
                    <div className={`mpaisa mb-3 ${!!itemm && itemm.amount < 10 ? 'disabled' : ''}`}>
                        <PhoneInput

                            inputProps={{
                                name: 'phone_number',
                                required: true,
                                autoFocus: true
                            }}
                            country={'ke'}
                            prefix=''
                            value={submittedData?.phone_number}
                            onChange={(value, country: countryProps, e, formattedValue) => {
                                handleOnChange(value, country, e, formattedValue);
                                setPhone(formattedValue.substring(country?.dialCode?.length + 1))
                                setphoneinit(false);
                                setcountry_code(country.dialCode);
                                setPhoneVlide(value)
                            }}
                            inputClass={"inputBx-mobile"}
                        />
                        {
                            phoneinit == false ?
                                !!phoneVlide ? (phoneVlide.length != 12 ? <div className="red invalid-feedback d-block">Please enter 9 digit phone number</div> : '') : <div className="red invalid-feedback d-block">Phone number is required</div> : ""
                        }
                    </div>

                )}
                {!!itemm && itemm.amount < 10 ? <div style={{ padding: "10px 0px" }} className='mpesa-container__errorMsg'>
                    For Send Money, please enter an amount greater than or equal to 10.</div> : ""}
                <div className={`mpesa-container__option-wrapper ${option === 'pay bill' ? 'border-green' : ""}`} onClick={() => {
                    setErrorShow(false)
                    setPaybleOption(true)
                    // if (!!balance[0]?.total_balance && feesNew && itemm && itemm.amount) {
                    // if ((balance[0]?.total_balance / 100) <= (feesNew['MPESA B2B PAYBILL'] + itemm.amount)) {
                    // setPaybleOption(false)
                    // setErrorShow(true)
                    // }

                    // }
                    setOption('pay bill')
                }}>
                    <div className='mpesa-container__option-wrapper__txt' style={{ display: "flex", alignItems: "center" }}>
                        <div className="imgMetheds">
                            <img src='https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/mpesa-kenya.jpeg' width="100%" height="100%" alt="" />
                        </div>
                        Paybill Number</div>
                    {option === 'pay bill' ? (<UpArrow />) : (<DownArrow />)}
                </div>
                {option === 'pay bill' && (
                    <div>
                        <InputGroup className="input-wrapper input">
                            <Form.Control
                                className='mpesa-form-input'
                                placeholder="Input paybill number here."
                                value={submittedData?.paybill_number}
                                onChange={handleSubmit}
                                name='paybill_number'
                                aria-describedby="basic-addon3"
                            />
                        </InputGroup>
                        <InputGroup className="input-wrapper input">
                            <Form.Control
                                className='mpesa-form-input'
                                placeholder="Input account number here."
                                value={submittedData?.account_number}
                                onChange={(e: any) => {
                                    handleSubmit(e)
                                    const sanitizedValue = e.target.value.replace(/[^\w\s]/gi, ''); // Regex to remove special characters
                                    setError(e.target.value !== sanitizedValue ? true : false);
                                }}
                                name='account_number'
                                aria-describedby="basic-addon3"
                            />
                        </InputGroup>
                        {submittedData?.account_number?.length > 14 ? <div className="red invalid-feedback d-block"> The account number must not exceed 13 digits.</div> : ""}
                    </div>
                )}

                <div className={`mpesa-container__option-wrapper ${option === 'till number' ? 'border-green' : ""}`} onClick={() => {
                    setErrorShow(false)
                    setPaybleOption(true)
                    // if (balance[0]?.total_balance && feesNew && itemm && itemm.amount) {
                    //     if ((balance[0]?.total_balance / 100) <= (feesNew['MPESA B2B TILL'] + itemm.amount)) {
                    // setPaybleOption(false)
                    // setErrorShow(true)
                    //     }
                    // }

                    setOption('till number')
                }}>
                    <div className='mpesa-container__option-wrapper__txt' style={{ display: "flex", alignItems: "center" }}>
                        <div className="imgMetheds">
                            <img src='https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/mpesa-kenya.jpeg' width="100%" height="100%" alt="" />
                        </div>
                        Till Number</div>
                    {option === 'till number' ? (<UpArrow />) : (<DownArrow />)}
                </div>
                {option === 'till number' && (<div>
                    <InputGroup className="input-wrapper input">
                        <Form.Control
                            className='mpesa-form-input'
                            placeholder="Input till number here."
                            value={submittedData?.till_number}
                            onChange={handleSubmit}
                            name='till_number'
                            aria-describedby="basic-addon3"
                        />
                    </InputGroup>
                </div>)}
                {errorShow == true ? (<div className='mpesa-container__errorMsg'>
                    Add funds to account to cover payment for the bill
                </div>) : ""}
                {ChackBalance == false ? <div className='mpesa-container__errorMsg'>
                    Please top up your account to cover the payment.
                </div> : ""}
                <div className='mpesa-container__btn-row'>
                    <Button className='mpesa-container__btn-row__white' onClick={() => onCancel()}>Cancel</Button>
                    {datadisplay == "Express Payment KES" ? <>
                        {button_desebal == false ?
                            <Button
                                disabled
                                className='mpesa-container__btn-row__green'
                                onClick={() => {
                                    onSubmitted()
                                }}>Proceed {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}</Button>
                            :
                            <Button className='mpesa-container__btn-row__green'
                                disabled={option === '' ||
                                    paybleOption == false ||
                                    (option == 'send money' && phoneVlide.length != 12) ||
                                    (option == 'till number' && !submittedData.till_number)
                                    || (option == 'pay bill' && (!submittedData.account_number || !submittedData.paybill_number) || submittedData?.account_number?.length > 14)
                                }
                                onClick={() => {
                                    if (option === 'pay bill') {
                                        dataadd()
                                        onSubmitted()
                                        setChackBalance(true);
                                    } else if (option == 'till number') {
                                        // till_numberFun()
                                        onSubmitted()
                                        setChackBalance(true);
                                    } else {
                                        onSubmitted()
                                        setChackBalance(true);
                                    }
                                }}>Proceed {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}</Button>}
                    </> : <>
                        <Button className='mpesa-container__btn-row__green'
                            disabled={option === '' || paybleOption == false ||
                                (option == 'send money' && phoneVlide.length != 12) ||
                                (option == 'till number' && !submittedData.till_number)
                                || (option == 'pay bill' && (!submittedData.account_number || !submittedData.paybill_number) || submittedData?.account_number?.length > 14)
                            }
                            onClick={() => {
                                if (option === 'pay bill') {
                                    dataadd()
                                    onSubmitted()
                                    setChackBalance(true);
                                } else if (option == 'till number') {
                                    // till_numberFun()
                                    onSubmitted()
                                    setChackBalance(true);
                                } else {
                                    onSubmitted()
                                    setChackBalance(true);
                                }
                            }}>Proceed   {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}</Button>
                    </>}
                </div>
            </div>
        </>
    )
}

const DownArrow = () => (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.03613 4.91386L8.8028 1.14053C8.86529 1.07855 8.91488 1.00482 8.94873 0.92358C8.98257 0.84234 9 0.755203 9 0.667195C9 0.579187 8.98257 0.49205 8.94873 0.41081C8.91488 0.329571 8.86529 0.255837 8.8028 0.193862C8.67789 0.0696944 8.50893 0 8.3328 0C8.15668 0 7.98771 0.0696944 7.8628 0.193862L4.52947 3.49386L1.22947 0.193862C1.10456 0.0696944 0.935593 0 0.759469 0C0.583344 0 0.414377 0.0696944 0.289469 0.193862C0.226477 0.255605 0.176363 0.329235 0.142032 0.410485C0.1077 0.491735 0.0898352 0.57899 0.089469 0.667195C0.0898352 0.7554 0.1077 0.842655 0.142032 0.923905C0.176363 1.00516 0.226477 1.07879 0.289469 1.14053L4.05613 4.91386C4.11856 4.98153 4.19433 5.03554 4.27866 5.07248C4.363 5.10941 4.45407 5.12848 4.54613 5.12848C4.6382 5.12848 4.72927 5.10941 4.81361 5.07248C4.89794 5.03554 4.97371 4.98153 5.03613 4.91386Z" fill="#DDDDDD" />
    </svg>
);
const UpArrow = () => (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.05371 0.214556L0.287043 3.98789C0.224557 4.04986 0.174961 4.1236 0.141115 4.20484C0.107269 4.28608 0.0898438 4.37322 0.0898438 4.46122C0.0898438 4.54923 0.107269 4.63637 0.141115 4.71761C0.174961 4.79885 0.224557 4.87258 0.287043 4.93456C0.411951 5.05872 0.580919 5.12842 0.757043 5.12842C0.933166 5.12842 1.10213 5.05872 1.22704 4.93456L4.56038 1.63456L7.86038 4.93456C7.98528 5.05872 8.15425 5.12842 8.33037 5.12842C8.5065 5.12842 8.67547 5.05872 8.80037 4.93456C8.86337 4.87281 8.91348 4.79918 8.94781 4.71793C8.98214 4.63668 9.00001 4.54943 9.00037 4.46122C9.00001 4.37302 8.98214 4.28576 8.94781 4.20451C8.91348 4.12326 8.86337 4.04963 8.80037 3.98789L5.03371 0.214556C4.97128 0.146886 4.89551 0.0928807 4.81118 0.0559425C4.72685 0.0190043 4.63578 -6.58035e-05 4.54371 -6.58035e-05C4.45164 -6.58035e-05 4.36057 0.0190043 4.27624 0.0559425C4.19191 0.0928807 4.11614 0.146886 4.05371 0.214556Z" fill="#DDDDDD" />
    </svg>
);